import React, { useRef } from 'react';


import Me from './assets/Me.jpg'
import { motion } from "framer-motion"
import Projects from './Components/Projects'
import Background from './Components/background'
import Links from './Components/Links'

const Home = (props) => {
  
  const projectsSectionRef = useRef(null);

  const scrollToProjects = () => {
     console.log("Button clicked"); // Add this line
    projectsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  
  return (
    <div className=' mt-16' >

        <section className='py-10'>
           
          
          <div className='text-center p-10  '>
          
            
            <h2 id='HOME' className='text-5xl pt-2 text-teal-600 font-medium md:text-6xl select-none' >Trent Black</h2>
            <h3 className='text-2xl pt-2 md:text-3xl text-white select-none' >Computer Engineer</h3>
            
          </div>
        
          <motion.div className='mx-auto w-80 md:w-[20%] bg-gradient-to-t from-gray-900 to-teal-600 rounded-full'  whileTap={{ scale:.85}}  whileHover={{ scale:1.2}}>
            <img alt='my pic'  draggable="false" className=' rounded-3xl mx-auto w-48 md:h-[100%] '  src={Me} />
          </motion.div>
          {/* <div className='mt-10'> */}

              {/* <motion.div whileTap={{ scale:.9}}   whileHover={{ scale:1.2}} className='text-center shadow-xl rounded-xl bg-gray-500  mx-[10%] p-5 my-5  mdsm:mx-[10%] lg:mx-[30%] md:mx-[10%] md:px-10 md:py-0 md:m-2 m-5 ' > */}
            {/* <h3 className='text-3xl  text-white pt-10 md:pt-0'> My objective </h3> */}
            {/* <p  className='font-Roboto  select-none  md:text-xl  lg:text-2xl  leading-8 text-gray-900 sm:text-sm mx-4 py-10 sm:mx-5' > 
              
              I am <span className='text-gray-200'>knowledgeable</span>  in a wide range of programming languages and technologies. I have a keen understanding of how to design and implement complex systems.

              I am a <span className='text-gray-200'>strong problem solver</span>  and I am able to <span className='te4802348329408943928029849xt-gray-200'> quickly identify and troubleshoot</span>  issues with computer systems.

              I am also a <span className='text-gray-200'>great communicator</span> and I am able to <span className='text-gray-200'>effectively</span> collaborate with others on projects. Overall, I am a valuable asset to any team and I am always <span className='text-white'> working hard </span>to push the boundaries of what is possible with technology.
            </p> */}
            
            
            
            {/* </motion.div> */}

          {/* </div> */}
          
         
        </section>
       
        <Projects id="projects" ref={projectsSectionRef} /> {/*  Found in components*/}



        
        <Background/> {/*  Found in components*/}
        
        <Links  scrollToProjects={scrollToProjects}/>


       
        <div>
            <button ></button>
             
             

         </div>
   
    </div>
  )
}

export default Home