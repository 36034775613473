import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Logo from '../assets/Logo.PNG'
// import Resume from '../assets/Trent_Black_Resume-2024.pdf'
import { delay, motion } from "framer-motion"
import ScrollLink from "react-scroll/modules/components/Link"; // Import as ScrollLink
import ColorCycleText from './cycleColor';
import Modal from 'react-modal';

const DownloadModal = ({ isOpen, onCancel, onConfirm }) => {
  // console.log('Button clicked');
  return (
    <Modal isOpen={isOpen} className="download-modal flex items-center justify-center">
      <div className="modal-content bg-white p-4 rounded  mt-24">
        <p>Do you want to download the resume?(PDF)</p>
        <div className="mt-4 flex justify-end">
          <button className="mr-2 px-4 py-2 bg-gray-100 rounded" onClick={onCancel}>
            Cancel
          </button>
          {/* <button className="px-4 py-2 bg-blue-400 text-white rounded" onClick={onConfirm}>
            Download
          </button> */}
        </div>
      </div>
    </Modal>
  );
};




const Navbar = (props) => {
    let Links =[
      // {name:"", link:"/", id:"HOME"},
      //{name:<ColorCycleText text="PROJECTS" interval={1000} />, link:"/", id:"PROJECT"},

      // {name:"ABOUT",link:"/about"},
      // {name:"CONTACT", link:"/contact"},
      // {name:"", link:"/contact"},

      ,

    ];
    let [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const onButtonClick = () => {
    // Toggle the mobile menu
    setOpen(!open);
    // If the modal is not already open, open it
    if (!showModal) {
      setShowModal(true);
    }
    };

    const onCancel = () => {
      setShowModal(false);
    };

    const onConfirm = () => {
      setShowModal(false);

      // Using JavaScript method to get the PDF file
      // fetch(Resume).then((response) => {
      //   response.blob().then((blob) => {
      //     // Creating a new object for the PDF file
      //     const fileURL = window.URL.createObjectURL(blob);
      //     // Creating an anchor element to trigger the download
      //     let aLink = document.createElement('a');
      //     aLink.href = fileURL;
      //     aLink.download = 'Trent_Black_2023';
      //     aLink.click();
      //   });
      // });
    };

    
   
  return (
    <div className=' NavBar z-[9999] fixed'>
   
    <div className='shadow-md w-full fixed top-0 left-0'>
      <div className='md:flex items-center justify-between bg-gray-100 py-4 md:px-10 px-7'>
      <div className='font-bold text-2xl cursor-pointer flex items-center font-[Poppins] 
      text-gray-800'>
        <a href="/">
          <img className="rounded-sm bg-transparent w-[100%] h-12" src={Logo} alt="Logo" />
        </a>
      </div>
      
      <div onClick={()=>setOpen(!open)} className='text-3xl absolute text-blue-900 right-8 top-6 cursor-pointer md:hidden'>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            <path fillRule="evenodd" d="M3 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 5.25zm0 4.5A.75.75 0 013.75 9h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 9.75zm0 4.5a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75zm0 4.5a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clipRule="evenodd" />
            </svg>


      </div>

      <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-gray-100 md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-20 ':'top-[-490px]'}`}>



        {
          Links.map((link)=>(
            <motion.li whileTap={{ scale:1.25}}  whileHover={{ scale:.85}} key={link.name} className='md:ml-8 text-xl  md:my-0 my-7'>
              
              <ScrollLink
              className=' text-gray-800 hover:text-gray-400 duration-500 select-none cursor-pointer'
              to={link.id}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-70}
              >
              {link.name}
              </ScrollLink>
            </motion.li>
          ))
        }
        <motion.li
          whileTap={{ scale: 1.25 }}
          whileHover={{ scale: 0.85 }}
          className='md:ml-8 text-xl md:my-0 my-0 sm:my-5'
        >
          <a
            href="https://github.com/trentbyu" // Replace with your GitHub profile link
            className='text-gray-800 hover:text-gray-400 duration-500 select-none cursor-pointer '
          >
            <img src={'https://github.githubassets.com/assets/GitHub-Mark-ea2971cee799.png'} alt="GitHub Logo" className=" sm:mx-[60%] sm:w-20 sm:h-20 h-12" />
          </a>
        </motion.li>
        <motion.li
          whileTap={{ scale: 1.25 }}
          whileHover={{ scale: 0.85 }}
          className='md:ml-8 text-xl md:my-0  my-0 sm:my-5'
        >
          <a
            href="https://www.linkedin.com/in/trentblack010/" // Replace with your GitHub profile link
            className='text-gray-800 hover:text-gray-400 duration-500 select-none cursor-pointer'
          >
            <img src={'https://t3.ftcdn.net/jpg/04/37/88/86/360_F_437888641_XrjjuAwATXWNx10jQurCDaXXJnobhDi4.jpg'} alt="GitHub Logo" className=" sm:mx-[60%] m:w-20 sm:h-20 h-12" />
          </a>
        </motion.li>
         {/* <motion.li whileTap={{ scale: 1.25 }} whileHover={{ scale: 0.85 }} className='md:ml-8 text-xl md:my-0 my-7'>
              <a className='text-gray-800 hover:text-gray-400 duration-500 select-none cursor-pointer' onClick={onButtonClick}>
                RESUME
              </a>
            </motion.li> */}
         
      
       
      </ul>
      </div>
    </div>
          {showModal && <DownloadModal isOpen={showModal} onCancel={onCancel} onConfirm={onConfirm} />}


    </div>
  );
};

export default Navbar