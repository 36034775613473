
import React from "react";

import { delay, motion } from "framer-motion"
import { Link } from 'react-router-dom';
const Links  =  ({ scrollToProjects  })=> {


    return(
 <div className="mx-[11%] grid grid-cols-1 gap-8 lg:grid-cols-2">
 

  {/* Projects Section */}
 <button onClick={scrollToProjects}>
  <motion.section
    whileTap={{ scale: 0.8 }}
    whileHover={{ scale: 1.1 }}
    className="select-none bg-gray-300 py-4"
  >
    <h2 className="text-2xl font-bold mb-4 text-center">Projects</h2>
  </motion.section>
</button>

  {/* Previous Jobs Section */}
  <Link to="/prejobs">
    <motion.section
      whileTap={{ scale: 0.8 }}
      whileHover={{ scale: 1.1 }}
      className="select-none bg-gray-300 py-4"
    >
      <h2 className="text-2xl font-bold mb-4 text-center">Previous Experience</h2>
    </motion.section>
  </Link>
</div>

    )

}

export default Links; 