
import React from 'react'
import NavBar from './Components/NavBar';

import Home from './Home';
import { Route,Routes } from 'react-router-dom'
import Prejobs from './previous-jobs';
import ReactGA from "react-ga";

const TRACKING_ID = "G-XPBN89JV68"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>

      <main className='bg-gray-700'>
        <NavBar className="NavBar" />
        <div>
         

            <Routes>
              <Route exact path='/' element={<Home />}> </Route>
              <Route path='/prejobs' element={<Prejobs />} />
              {/* <Route path='/contact' element={<Contact />}> </Route> */}
             

              
            </Routes>
        <a href="#" onclick="window.open('https://www.sitelock.com/verify.php?site=trentblacksportfolio.com','SiteLock','width=600,height=600,left=160,top=170');" ><img class="img-fluid" alt="SiteLock" title="SiteLock" src="https://shield.sitelock.com/shield/trentblacksportfolio.com" /></a>

        
          
        </div>

        
  

      </main>
    

    </>
     
  
  );
}

export default App;
