import React from 'react';
import YouTube from 'react-youtube';

const YouTubePlayer = ({ videoId }) => {
  const screenSize = window.innerWidth;

  let width = '100%';
  let height = '100%';

  if (screenSize >= 768 && screenSize < 1024) {
    width = '100%';
    height = '100%';
  } else if (screenSize >= 1024) {
    width = '100%';
    height = '100%';
  }

  const opts = {
    width,
    height,
    playerVars: {
      // Add any additional player parameters here
    },
  };

  return (
    <YouTube
      className=" mt-2 mx-[auto] w-62 h-48  shadow-lg lg:w-[53%] lg:h-[53%]"
      videoId={videoId}
      opts={opts}
    />
  );
};

export default YouTubePlayer;


