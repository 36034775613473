import React, { useState } from "react";
import './Popup.css'
import YouTubePlayer from "./yt";
import {motion } from "framer-motion"

export default function Modal(props) {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };
  

//   if(modal) {
//     document.body.classList.add('active-modal')
//   } else {
//     document.body.classList.remove('active-modal')
//   }

  return (
    <>
      <button onClick={toggleModal} className="btn-modal">
        {props.children}
      </button>
      
      {modal && (
        <div className="popup-container ">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="popup-body left-[8%] right-[8%] md:right-[20%] md:left-[20%]" >
            
            <button className="text-left text-lg bg-gray-700 p-1 m-2 text-white rounded-md" onClick={toggleModal}>
              CLOSE
            </button>
            
            <h2 className="text-center text-xl mb-4 mb:my-6">{props.name}</h2>
            
            {/* <img src={props.img}  className=" mx-auto w-48 rounded-full"></img> */}
            <p className="mx-2 text-xs md:text-sm lg:text-lg font-Roboto text-center mb-4 md:my-6 md:mx-32">{props.des}</p>
            
            
             <YouTubePlayer videoId={props.vid} />
            <motion.div whileTap={{ scale:1.8 ,y:-150}}   whileHover={{ scale:2.5 , y:-150}}>
               
              <img src={props.pic} alt="" className=" mt-2 mx-[auto] w-48 h-34  shadow-lg md:w-[50%] md:h-[50%] lg:w-[20%] lg:h-[20%] "/>
                
            </motion.div>




          </div>
        </div>
      )}
    </>
  );
}