
import React from "react";
import Micro from '../assets/Micro.jpg'
import { delay, motion } from "framer-motion"

const Background  = (props) => {


    return(
         <section  className='pt-5' >
           
            <div className='select-none'>

            <motion.div   className='  lg:flex gap-8' > 
              
              <motion.div whileTap={{ scale:.8}} className=' mx-[10%] bg-gray-500 lg:mx-auto md:mx-[10%] text-center shadow-xl rounded-xl md:p-20   md:my-10 my-5 p-5'  whileHover={{ scale:1.1}}> 
                <img alt="" className=' aspect-square bg-black mx-auto lg:mx-10  xl:mx-auto rounded-full' width={120} height={100} src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrN7gIgEf7vLT54ZVxU2Uf_mLweb_gmWPZ_A&usqp=CAU'}  /> 
                <h3 className='  text-3xl font-medium pt-8 pb-2'> Operating Systems</h3>
                <p className='  lg:text-md pb-2 '>   Experience with multiple Operating Systems. </p>

                <h4 className=' md:text-md py-4 text-white font-bold'> Operating Systems knowledge </h4>

                <div className='font-BebasNeue text-2xl flex flex-row flex-wrap'>
                <p className=' mx-auto text-dark px-1  '> Windows  | FreeRTOS  | Linux </p>
                
                
                
                </div>

              </motion.div>
              <motion.div whileTap={{ scale:.8}} className=' mx-[10%] bg-gray-500 lg:mx-auto md:mx-[10%] text-center shadow-xl rounded-xl md:p-20   md:my-10 my-5 p-5'  whileHover={{ scale:1.1}}> 
                <img alt="" className=' aspect-square  mx-auto lg:mx-10  xl:mx-auto rounded-full' width={120} height={100} src={Micro}  /> 
                <h3 className='  text-3xl font-medium pt-8 pb-2'> Micro processors / FPGA</h3>
                <p className=' lg:text-md pt-2 '>  Necessary skills and knowledge to design and</p>
                <p className='  lg:text-md pb-2 '>   implement FPGA / Microprocessors </p>
                <h4 className=' md:text-md py-4 text-white font-bold'> Hardware knowledge </h4>
                <div className='font-BebasNeue text-2xl flex flex-row flex-wrap'>
                <p className='mx-auto text-dark-100 px-1'>  stm32 |Raspberry pi  |Esp 32 |Basys 3| + more </p>
                

                </div>

              </motion.div>
              <motion.div whileTap={{ scale:.8}} className=' mx-[10%] bg-gray-500 lg:mx-auto md:mx-[10%] text-center shadow-xl rounded-xl md:p-20   md:my-10 my-5 p-5'  whileHover={{ scale:1.1}}> 
                <img alt="" className=' aspect-square  mx-auto lg:mx-10  xl:mx-auto rounded-full ' width={120}  height={100} src='https://www.lifewire.com/thmb/FLUx7EC-9Zrv7aMQuH1qmM5o7Ww=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/what-is-binary-and-how-does-it-work-4692749-1-1eaec2e636424e71bb35419ef8d5005b.png'  /> 
                <h3 className=' text-3xl font-medium pt-12 pb-2 '> Language Proficiency </h3>
                <p className=' lg:text-md pt-2 '> Ability to design, develop, </p>
                <p className=' lg:text-md pb-2 '> and maintain software.</p>

                <h4 className=' md:text-md py-4 text-white font-bold'> Languages </h4>

                <div className='font-BebasNeue text-2xl flex flex-row flex-wrap'>
                  <p className='mx-auto text-dark px-1'> C |C++ |Python |Verilog</p>
               

                </div>
               
              </motion.div>
            </motion.div>
            </div>

        </section>
    )

}

export default Background;