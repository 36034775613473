import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import {motion } from "framer-motion"

import Modal from './Popup2'



import ai from '../assets/Dream_TradingCard.jpg'
import djangopic from '../assets/Django.png'
import mazeImg from '../assets/maze.png'
import cpu from '../assets/Computer.png'
import america from '../assets/US.png'
import rocket from '../assets/rocket.png'
import Song from '../assets/SongAI.jpg'
import Pump from '../assets/waterP.jpg'
import TFT from '../assets/TFT.jpg'
import WIFIBTNPIC from '../assets/ESP32.png'
import bitadderpic from '../assets/4BitAdder.jpg'
import Minst_pic from '../assets/Minst_pic.png'
import Rust_pic from '../assets/Rust.jpg'
import CPLUS_pic from '../assets/C++.png'
import Heart_pic from '../assets/Heart.png'
import numpad_pic from '../assets/numberpad.png'
import Light_Switch from '../assets/Light_Switch.png'
import LED from '../assets/led.png'
import bitcpu from '../assets/4bitcpu.png'
import keypad from '../assets/keypadPic.png'
import face from '../assets/face.png'
import fpgaCal from '../assets/FPGA.png'
import AssemblyPic from '../assets/ASM.png'
import Times from "../assets/Times.png"
import Powerreg from "../assets/PowerReg.jpg"
import PowerIMG from "../assets/PowerIMG.PNG"
import AMP from "../assets/AMP.PNG"
import SeniorImg from "../assets/Senior.png"
import DoorBell from "../assets/DoorBell.jpg"







import SystemSecure from"../assets/GPIOSecure.png"



import ReactGA from "react-ga";
// const fpgaCal = "../"
const  bitAdder = "iOWJB4qQdGg"
const Django = "HaxSNe2JSlw"
const WIFIBTN = "V3ELeRxobqM"
const esp32Screen = 'iz24ujhgUvY'
const KEYPAD = '9Qx9aMXa1pE'
const PumpW = 'Pk_DzeymKfg'
const Maze = 'N-nxVzk3N70'
const Bird = 'NzvBT37XjE0'
const physics =  'rrPleOZguBs'
const arc = 'xxjsacbjVm0'
const Sever = 'drwDVZiwq8A'
const Reader = '0hZ6Z3ENJpg'
const MINST = 'Nl5OOHHi46Q'
const Rust = 'jF123H_miPM'
const CPLUS_VID = 'v_6gSq-rHWo'
const Heart = '8J4c50NGgQk'
const numpad = 'JkCRutc5i2Q'
const Blink = 'MaxdPd1eHp8'
const CPU = 'gS1uJYcQ8bc'
const bitmul8 = '-9PFtvJoWy0'
const Assembly = "00-gOhYPUmQ"
const BareC = "jHyiMoLpin4"
const fpgaCAlVid = "l4BcVvNuLTw"
const esp32GuiVid = "DDHaBsyZY7k"
const esp32web = "881CQSX3tQo"
const PowerReg = "ORoKvQLiF9k"
const AMPVID = "m89zGfeoifI"
const Senior = "dAvXODR1tSE"
const Homeauto = "GQALkA6ou8U"




const Projects = React.forwardRef((props, ref) =>  {
     let icons = [ 
      { topic: "Micro Processors", key: 1231, vid: Senior ,pic: null, img: SeniorImg , title: "Senior Project ",  des:"For my senior project, I developed an RF node capable of bi-directional data transmission using directional antennas as part of our senior project. Our device uses a unique technique called \"directional antenna locking\" to ensure consistent data transmission regardless of a structure's orientation. Unlike omnidirectional antennas, directional antennas can only send data in one direction, allowing a stronger connection and preventing interference with other signals on the same frequency. My responsibilities included soldering, designing, and programming the ESP32, where I utilized FreeRTOS to take advantage of its dual-core architecture." },
      { topic: "Micro Processors", key: 1231, vid: Homeauto ,pic: null, img: DoorBell , title: "Home Automation",  des:"I developed a website using React to display information from my network of ESP32 sensors. Each ESP32, connected to a variety of sensors, sends data to a backend server. This information is then displayed on the frontend via a Flask and React framework. The ESP32s, running FreeRTOS, have the capability to stream video feeds and function as smart doorbells. The website's interface allows for the seamless integration of multiple ESP32 devices, offering users the flexibility to enhance their homes with various smart features." },

      { topic: "Micro Processors", key: 1231, vid: esp32web ,pic: null, img: face , title: "Face detection",  des:"This is an ESP32 Cam i designed the code to transmit the images via a web server. At the receiving end, my Python script engages in facial detection, converts the displayed image to grayscale, and subsequently saves the detected face in color. Additionally, the surrounding area of the face is intentionally blacked out for the privacy of my apartment." },
      { topic: "Other" , key:125, vid: PowerReg ,pic: PowerIMG, img: Powerreg, title:'Power supply', des:"This Power supply project is to design and construct a fixed DC Output Power Supply with a current limit that can be either AC or DC powered. Practical power supplies are also called voltage regulators and approximate an ideal voltage source by maintaining a constant output voltage over a wide range of load current values."},
      
      { topic: "Micro Processors" , key:7,pic: null, vid: PumpW , img: Pump, title:'Water Pump', des:"This water pump has been designed and built using a 9 volt pump controlled by an Arduino. The pump can be turned on and off using a TV remote, making it convenient to control the pump remotely. This type of water pump could be useful in a variety of applications, such as for watering plants or for pumping water in a fountain. The use of an Arduino to control the pump allows for the pump to be easily programmed and customized to meet the specific needs of the user. It is a practical and innovative solution for pumping water."},
      
      { topic: "Other" , key:125, vid: AMPVID ,pic: null, img: AMP, title:' Equalizer, Mixer, and Preamp', des:"The first PCB is dedicated to bass and treble equalization, providing precise control over audio frequencies. The second PCB functions as a microphone preamplifier, featuring audio controls and a left-right audio mixer for sound manipulation. "},
      
      { topic: "Micro Processors" , key:15, vid:KEYPAD ,pic: null, img: keypad, title:'KeyPad', des:"I Designed this key pad using kicad and fusion 360. I was then able to write the firmware for the keypad using c++. The processor used was a raspberry pi pico"},
      { topic: "Python", key: 1, vid: Maze ,pic: null, img: mazeImg , title: "Maze Solver",  des:"maze solver is a program that is able to find a path through a maze from a starting point to an ending point. Blue are possible routes, red are walls, and white is the path taken." },
      
      { topic: "Micro Processors" , key:73, vid:CPU ,pic: null, img: bitcpu, title:'4 bit cpu', des:"To display ASCII codes to a TTY (teletypewriter) using a 4-bit CPU, i used two registers to store the ASCII code. This cpu has 13 different op codes add, multiply, subtract, jump and more. "},

      { topic: "Other" , key:10, vid: CPLUS_VID ,pic: null, img: CPLUS_pic, title:'C++', des:"C++ is a general-purpose, object-oriented programming language that was developed as an extension of the C programming language. It was created by Bjarne Stroustrup at Bell Labs in the early 1980s, and has since become one of the most widely-used programming languages in the world.."},
      { topic: "FPGA", key: 102, vid: bitmul8 ,pic: null, img: Times , title: "8bit multiplier",  des:"Creating an 8-bit multiplier in Verilog without using the '*' operator involves designing a custom digital circuit that takes two 8-bit binary numbers as inputs and uses logic gates to perform bit-wise multiplication. The process includes bit slicing, clock and control logic, testing, and synthesis for FPGA or ASIC implementation. This approach offers a deeper understanding of digital hardware design and arithmetic operations." },

      { topic: "Python", key:3, vid: Bird , pic: null,img: ai, title:'Ai Game Learner', des:"Artificial intelligence (AI) can be used to train a computer to play games, such as Flappy Bird. Using a neuroevolution algorithm, such as NEAT the bird is moved by the computer." },
      { topic: "Micro Processors" , key:15, vid:esp32GuiVid ,pic: null, img: "https://europe1.discourse-cdn.com/arduino/original/4X/d/6/d/d6d146d2f4c79b01cf9f5941ff9950ee44e98f5e.jpeg", title:'ESP 32 custom GUI', des:"This is a GUI menu system with a rotary encoder and TFT display on an ESP32 microcontroller. The project involves creating a menu with multiple options displayed on the TFT screen, allowing the user to navigate through the menu using a rotary encoder, and performing actions based on the selected menu option. The esp32 also communicates with the arduino uno r3 via UART"},
      { topic: "FPGA" , key:9, vid: bitAdder ,pic: null, img: bitadderpic, title:' 4 Bit Adder', des:"4-bit adder is a digital circuit that is used to add two 4-bit binary numbers together. It has two 4-bit input ports, named 'a' and 'b', which accept the two numbers to be added. It also has a 1-bit input port called 'cin' (carry-in), which is used to handle any carry-over from a previous addition operation. The adder generates a 4-bit output port called 'sum', which represents the result of the addition, and a 1-bit output port called 'cout' (carry-out), which indicates if there is a carry-over to the next addition operation."},
      
      
      ,
      { topic: "Micro Processors", key: 1027, vid: BareC ,pic: null, img: SystemSecure , title: "GPIO & Bitwise Security System",  des:"This is an innovative security system written in bare C that leverages bitwise operations to monitor and secure a location. The system incorporates three sensors, each tracking a door, a window, and a back door. When any of these sensors detects unauthorized access, an LED is triggered, alerting the user to the breach. The program's efficiency lies in its ability to swiftly respond to security threats while utilizing GPIO for robust control, making it a dependable and effective security solution." },
      { topic: "Micro Processors", key: 1026, vid: Assembly ,pic: null, img: AssemblyPic , title: "Buttons in Assembly",  des:"This ARM7-based code for STM32 microcontrollers features two buttons and on-board storage access. Written in ARM assembly , it initializes GPIO pins for the buttons, configures button-related registers, and enters a loop to continuously monitor button states. Additionally, it employs on-board storage, such as sram for data storage and retrieval. This code offers efficient control over both hardware components and storage." },
      { topic: "FPGA", key: 102, vid: fpgaCAlVid ,pic: null, img: fpgaCal , title: "FPGA calculator w Memory",  des:" This FPGA setup is designed to respond to different button combinations for actions like addition, subtraction, division, and bitwise AND operations. The 'up' button enables Write Enable (WE), storing four binary numbers from the left switches into a memory location decided by the right most switches. The middle eight switches on the FPGA control two memory addresses, the data on these addresses can be used for addition and subtraction or other operations. This makes the whole system interactive, giving the power to perform arithmetic operations and unitize memory using buttons and switches on the FPGA " },
      { topic: "Python", key:6, vid: Sever ,pic: null, img: cpu, title:'Client-Sever-Model', des:"This is a server-client model written in Python, the server is a program that runs on a specific machine and listens for incoming client connections. The clients are programs that connect to the server and send requests or data, which the server processes and responds to. The server and client communicate with each other using a specific network protocol, such as HTTP or TCP/IP."},

      
      { topic: "Micro Processors" , key:8, vid: esp32Screen ,pic: null, img: TFT, title:'TFT display', des:"This is an ESP32 TFT display it is a type of display that uses an ESP32 microcontroller and a TFT (thin-film-transistor) display to display images and graphics."},
      // { topic: "Micro Processors" , key:7, vid: esp32Screen , img: Pump, title:'TFT display', des:null},
      { topic: "Micro Processors" , key:10, vid: WIFIBTN ,pic: null, img: WIFIBTNPIC, title:'Wireless Connect', des:" One ESP32 acts as the server, with a button connected to it that posts data to an HTTP endpoint. The other ESP32 acts as the client and receives the data from the server. This data is then displayed on a TFT display, which changes from 'high' to 'low' depending on if the button on the server has been pressed."},
      { topic: "Python" , key:119, vid:MINST ,pic: null, img: Minst_pic, title:'Minst Dataset', des:"In the fields of machine learning and computer vision, the MNIST (Modified National Institute of Standards and Technology) dataset is a frequently used dataset. With 60,000 training photos and 10,000 test images, it consists of a sizable collection of handwritten digits. Each picture has a size of 28x28 pixels and a single grayscale number from 0 to 9."},
      { topic: "Micro Processors" , key:11, vid:Heart ,pic: null, img: Heart_pic, title:'8x8 display', des:"An Arduino-powered 8x8 pixel display of a heart is a straightforward graphical representation of a heart shape made up of individual pixels. Typically, an array of 8x8 numbers is used to generate the heart, with each integer value denoting the state of a single pixel (on or off). The condition of each pixel on the screen may then be controlled by the values in the array, creating a visual depiction of the heart."},
      { topic: "Micro Processors" , key:13, vid:numpad ,pic: null, img: numpad_pic, title:'NumberPad', des:"By hitting buttons on a numeric keypad, you can enter numerical data into an Arduino system using  an Arduino  number pad. Each button on a numeric keypad corresponds to a different digit from 0 to 9, and the buttons are organized in a grid layout."},
      { topic: "Other" , key:13, vid:Blink ,pic: [Light_Switch], img: LED, title:'Blinking Circuit', des:"Hover over the circuit to see it better. The blinking LED circuit uses a capacitor that charges and discharges through resistors to create a blinking effect. The transistor acts as a switch, turning the LED on and off as the voltage across the capacitor reaches certain thresholds. "},
      { topic: "Python" , key:7, vid: Reader ,pic: null, img: Song, title:'Ai Lyrics Creator', des:"This is an AI songwriter that takes the song lyrics of the artist Adele as input and generates new songs using TensorFlow and Python. This AI songwriter uses machine learning techniques to analyze Adele's lyrics and incorporate her style and tone into its own songs. It is an interesting development in the field of music creation, using AI to experiment with different artists and potentially generate unique and original songs."},
      
      { topic: "Python", key:4, vid: physics , pic: null,img: rocket , title:'Py Physics', des:"The kinematic equation physics simulator in Python is a computer program that uses Python code to simulate the motion of objects based on the principles of kinematics. Kinematics is the study of motion and how it is affected by forces, without considering the causes of the forces. The simulator uses a variety of kinematic equations, such as the position equation, velocity equation, and acceleration equation, to accurately model the motion of objects.  " },
      { topic: "Other" , key:15, vid: Rust ,pic: null, img: Rust_pic, title:'Rust', des:"Rust is a multi-paradigm programming language that was developed by Mozilla Corporation. It is designed to be a safe, concurrent, and practical language for systems programming."},
      { topic: "Python", key:2, vid: Django ,pic: null,img: djangopic , title:'Django', des:"Users can create an account and then use the tools provided by the website to build and customize their own resumes. They can add their work experience, education, skills, and other relevant information to their resumes and format them to look professional and visually appealing." },
      { topic: "Other", key:5, vid: arc ,pic: null, img: america, title:'Arc Js', des: "ArcGIS Developers is a platform and community for developers to create mapping and spatial analysis applications using the ArcGIS platform. The platform provides access to a range of tools and resources for building and deploying GIS applications, including APIs, SDKs, and developer libraries for various programming languages. The ArcGIS Developer community is a network of developers and GIS professionals who share resources and knowledge, and collaborate on the development of mapping and spatial analysis applications" },

      

   
    ]

    useEffect(() => {
      ReactGA.pageview(window.location.pathname);
    }, [])

    const [topic , setTopic] = useState("all")
    const [projects , setProjects] = useState(icons)


    const Python = () => {
      setTopic("Python")
      var arr = [
          
        ];
      icons.forEach(element => {
        if (element.topic === "Python"){
            arr.push(element)
        }
            
        });
      setProjects(arr)
      
    }
     const FPGA = () => {
      setTopic("FPGA")
      var arr = [
          
        ];
      icons.forEach(element => {
        if (element.topic === "FPGA"){
            arr.push(element)
        }
            
        });
      setProjects(arr)
      
    }

    const Other= () => {
      setTopic("Other")
      var arr = [
          
        ];
      icons.forEach(element => {
        if (element.topic === "Other"){
            arr.push(element)
        }
            
        });
      setProjects(arr)
      console.log(projects)
      
    }
    const ms = () => {
      setTopic("Micro Processors")
      var arr = [
          
        ];
      icons.forEach(element => {
        if (element.topic === "Micro Processors"){
            arr.push(element)
        }
            
        });
      setProjects(arr)
      console.log(projects)
      
    }
    const all = () => {
      setTopic("All")
      setProjects(icons)
    }
    return(
        <section id="projects" ref={ref}>
          
          <div  className=' sm:py-0 py-10'> 
            <motion.div whileTap={{ scale:.95}}  whileHover={{ scale:1.2}} className='text-center mx-auto  w-[50%] md:w-[15%] lg:w-[15%] h-11 bg-gradient-to-t from-gray-900 to-teal-600 rounded-full'>
              <h3 className='font-BebasNeue sm:text-2xl text-3xl text-gray-100 select-nonep-1' id='PROJECT'> Projects </h3>  
              

            </motion.div>

            <div className='flex flex-col mx-auto sm:my-5 mt-10'>
                <div className='flex flex-row mx-auto  p-2'>

                  <h4  className='font-BebasNeue text-3xl text-teal-600 select-none p-1'>  Filter  </h4>
                  <h4  className='font-BebasNeue  text-3xl text-teal-600 select-none p-1'> Topic: </h4>
                  <h4  className='font-BebasNeue  text-3xl text-teal-500 select-none p-1'> {topic}  </h4>


                </div>

                
                <div className=' mx-auto flex xsm:flex-col sm:flex-row  mt-5'>
                  <button onClick={all} className='bg-teal-600 md:mx-5 md:mt-5  mt-1 hover:bg-white hover:text-teal-600 text-white font-bold md:py-2 px-4 rounded-full   py-1 text-xs md:text-xl'>  ALL </button>

                  <button onClick={Python} className='bg-teal-600 md:mx-5 md:mt-5  mt-1 hover:bg-white hover:text-teal-600 text-white font-bold md:py-2 px-4 rounded-full   py-1 text-xs md:text-xl'>  Python  </button>
                  <button onClick={ms} className='bg-teal-600 md:mx-5 md:mt-5  mt-1 hover:bg-white hover:text-teal-600 text-white font-bold md:py-2 px-4 rounded-full   py-1 text-xs md:text-xl'>  Micro Processors   </button>
                  <button onClick={FPGA} className='bg-teal-600 md:mx-5 md:mt-5 mt-1 hover:bg-white hover:text-teal-600 text-white font-bold md:py-2 px-4 rounded-full   py-1 text-xs md:text-xl'>  FPGA   </button>
                  <button onClick={Other} className='bg-teal-600 md:mx-5 md:mt-5  mt-1 hover:bg-white hover:text-teal-600 text-white font-bold md:py-2 px-4 rounded-full   py-1 text-xs '> Other  </button>



                  
                </div> 
             

            </div>
            
          </div>
          <h1 class=" sm:text-lg text-center text-3xl text-gray-200 bg-teal-800 sm:mx-4 mx-80 sm:my-0 my-5 sm:px-3 p-4">
            ALL PROJECTS CAN BE CLICKED AND HAVE A VIDEO
          </h1>

          <motion.div className='flex flex-row flex-wrap gap-10 pb-5 mb-20 md:flex-row md:flex-wrap lg:flex-row lg:flex-wrap rounded-full' >
          

            

            {
              projects.map((Project)=>(
                <>
                  <div key={Project.key} className='mx-auto lg:basis-1/5 basis-1/4 flex-2 my-5'>
                    <Modal key={Project.key} name={Project.title} img={Project.img} des={Project.des} vid={Project.vid} pic={Project.pic}>
                      <motion.img
                        whileTap={{ scale: .6 }}
                        draggable="false"
                        className='aspect-square rounded-full object-cover mx-auto overflow-hidden w-[90%] h-[90%] md:w-[80%] md:h-[80%] lg:w-[60%] lg:h-[60%] cursor-pointer transition-all duration-300 ease-in-out'
                        layout="responsive"
                        src={Project.img}
                        whileHover={{ scale: 1.2, boxShadow: '0px 10px 50px rgb(0,0,0)' }}
                      />
                      <motion.h1
                        key={Project.key}
                        whileTap={{ scale: .9 }}
                        whileHover={{ scale: 1.3 }}
                        className='text-l text-center md:py-1 lg:py-5 md:text-2xl lg:text-2xl text-gray-100 cursor-pointer transition-all duration-300 ease-in-out hover:text-blue-500'
                      >
                        <FontAwesomeIcon icon={faPlay} /> {Project.title}  
                      </motion.h1>
                    </Modal>
                  </div>
                 
                  </>

                  
              ))
            }
          </motion.div>
          
        </section>
    )

    });

export default Projects